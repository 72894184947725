html, body {

    margin: 0;
    padding: 0;
    position: relative;
  height: 100%;
  font-family: 'Montserrat'
    
    }
    .Onas {
        background: url('../Components/images/welcomepage-logo.png') center/cover;
        min-height: 110vh;
        overflow-y: scroll;
    }
    
    .mainBox {
        margin-top: 8vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
     
    }
    
    .git {
        position: relative;
        margin-top: 2vh;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: transparent;
        backdrop-filter: blur(7px);
        color: rgb(255, 255, 255);
        padding: 10px;
        text-align: center;
        z-index: 0;
      }
    
    .photoBox {
        display: flex;
        flex-direction: column;
        
    }
    .startup {
        position: relative; /* Ensure relative positioning for child absolute elements */
    }
    .startup.pas {
        margin-left: 1px;
        font-size: 15em;
        color: black;
        visibility: visible;
        transition: 1s ease;
        z-index: 5;
    }
    .startup.act {
        font-size: 100em;
        color: transparent;
        visibility: hidden;
        transition: 1s ease;
        z-index: 10;
    }
    
    
    .naglowek_o_firmie {
        background-color: #393785;
        width: 190px;
        height: 45px;
        text-align: center;
        margin-left: 10%;
        border-radius: 40px;
        color:white;
        font-weight: 400;
        border: 0.01em solid black; 
        margin-top: 25px;
        
        margin-bottom: 0;
        
    }
    .naglowek {
        background-color: #393785;
        width: 150px;
        height: 45px;
        text-align: center;
        margin-left: 10%;
        border-radius: 40px;
        color:white;
        font-weight: 400;
        border: 0.01em solid black; 
        margin-top: -20px;
        margin-bottom: 0;
        
    }
    
    .photoBox ul {
        height: 100%;
        width: 100%;
        background-color: #393785;
        color: azure;
    }
    
    .Ofirmie {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        width: 70em;
        background-color: #393785;
        margin-left: 10%;
        text-align: left;
        color: azure;
        border-radius: 60px;
        height: fit-content;
        margin-bottom: 1em;
    }
    
    .Onas_css_kevin{
        display: flex;
        justify-content: center;
        flex-direction: row;
        width: 43em;
        background-color: #393785;
        align-items: center;
        text-align: left;
        color: azure;
        border-radius: 60px;
        height: 330px;
        margin-bottom: 11em;
        margin-left: 1%;
        top:0;
        justify-content: space-between;
        
    }
     
    .Onas_css_olek{
        display: flex;
        justify-content: center;
        flex-direction: row;
        width: 43em;
        background-color: #393785;
        align-items: center;
        text-align: left;
        color: azure;
        border-radius: 60px;
        height: 330px;
        margin-bottom: 11em;
        margin-left: 1%;
        top:0;
        justify-content: space-between;
        
    }
    
    
    .Ons {
        display: flex;
        justify-content: space-between;
        align-items: center; 
        border-radius: 40px;
        
    }
    
    .logo {
        width: 150px; /* Adjust the width as needed */
    height: 126px;
        overflow: hidden;
        cursor: pointer;
        background-color: transparent;
        
        object-fit:fill;
        
      }
    


    
      
      .Onas_css_element img {
        border-radius: 40px;
        height: 100%;
      }
      
      .Onas_css_element div {
        margin-top: 1vh;
        margin-left: 1vw;
        margin-right: 0.5vw;
      }
      
      .photoBox_element {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      
      .photoBox_element h1 {
        text-align: center;
      }
      
      .photoBox_element .Ofirmie {
        display: flex;
        justify-content: center;
        flex-direction: row;
        width: 70em;
        background-color: #393785;
        margin-left: 10%;
        text-align: left;
        color: azure;
        border-radius: 60px;
        height: 10.5em;
        margin-bottom: 1em;
      }
      
      .photoBox_element .Ofirmie div {
        margin-top: 0;
        margin-left: 4%;
        margin-right: 4%;
      }
      
      .photoBox_element .imgOnas {
        border-radius: 40px;
        height: 100%;
      }

    @media (max-width: 768px) {
        .Onas {
            min-height: 2vh;


        }
        .Onas_css_kevin{

            margin-bottom: 10em;

         }


        

        .naglowek_o_firmie {
            
            width: 46vw;
        }

        .naglowek {

            width: 35vw;
        }



        .Onas_css_olek {


            flex-direction: column;
            height: 65vh;
            width: 49vw;
            margin-right: 1vw;
            font-size: 1.3vh;
            
            color: white;
            background-color: #393785;
            border-radius: 50px;
        }
        .Onas_css_kevin {


            flex-direction: column;
            height: 65vh;
            width: 49vw;
            font-size: 1.3vh;
          
            
            color: white;
            background-color: #393785;
            border-radius: 50px;
        }

        .onas_tekst_olek {
            margin-bottom: 8vh;
        }
        .onas_tekst_kevin {
            margin-bottom: 8vh;
        }



        .Onas_css_element img {
            border-radius: 100px;
            height: 18vh;
            margin-top: 1vh;
            transform: translate(3%);
        }


        .Ofirmie {
            border-radius: 40px;
        }


        .photoBox_element {
            width: 90%;
            margin-left: 0%;
        }

        .photoBox_element .Ofirmie {
            flex-direction: column;
            align-items: center;
            width: 100%;
            height: 42vh;
            margin-left: 0;
           
        }

        .opis_o_firmie {
            text-align: center;
            font-size: 12px;
            margin-bottom: 0.4vh;
            
           }
        .photoBox_element .opis_o_firmie {
            margin-left: 0vw;
        }

        .photoBox_element .imgOnas {
            border-radius: 20px;
            height: 14vh;
            margin-top: 0.5vh;
            
        }

    }