*, *::after, *::before {
  box-sizing: border-box;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 200ms ease-in-out;
  border: 1px solid black;
  border-radius: 10px;
  z-index: 10;
  background-color: white;
  width: 510px;
  max-width: 80%;
  height: auto;
  max-height: 80%;
  z-index: 4; 
}

.modal.active {
  transform: translate(-50%, -50%);
}

.modal-header {
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid black;
}


.modal-header .title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: 0;
  font-family: source-code-pro, Menlo, Monaco, Console;
  height: 100%;
  color: #0b3389;

}

.modal-header .close-button {
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  font-size: 1.25rem;
  font-weight: bold;
}

.modal-body {
  padding: 2px 15px;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 0;
  font-family: source-code-pro, Menlo, Monaco, Console;
  height: auto;
  color: #000; ;
}

#overlay {
  position: fixed;
  opacity: 0;
  transition: 200ms ease-in-out;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .5);
  pointer-events: none;
}

#overlay.active {
  opacity: 1;
  pointer-events: all;
}

.submit-row-ankieta {
  display: flex;
  gap: 200px;
  justify-content: space-between;
  margin: 22px auto;
}

.button-questionnaire{
  display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
    color: #fff;
    background: #0b3389;
    background: #0b3389;
    border-radius: 50px;
    border-color: #fff;
    font-size: auto;
    font-weight: auto;
    cursor: pointer;
}

@media (max-width: 767px) {
  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 200ms ease-in-out;
    border: 1px solid black;
    border-radius: 10px;
    z-index: 10;
    background-color: white;
    width: 80vw;
    max-width: 80%;
    height: auto;
    max-height: 80%;
    z-index: 4; 
  }
  
  .modal.active {
    transform: translate(-50%, -50%);
  }
  
  .modal-header {
  
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid black;
  }
  
  
  .modal-header .title {
    font-size: 1.25rem;
    font-weight: bold;
    margin-top: 0;
    font-family: source-code-pro, Menlo, Monaco, Console;
    height: 100%;
    color: #0b3389;
  
  }
  
  .modal-header .close-button {
    cursor: pointer;
    border: none;
    outline: none;
    background: none;
    font-size: 1.25rem;
    font-weight: bold;
  }
  
  .modal-body {
    padding: 2px 15px;
    font-size: 1rem;
    font-weight: bold;
    margin-top: 0;
    font-family: source-code-pro, Menlo, Monaco, Console;
    height: auto;
    color: #000; ;
  }
  
  #overlay {
    position: fixed;
    opacity: 0;
    transition: 200ms ease-in-out;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
    pointer-events: none;
  }
  
  #overlay.active {
    opacity: 1;
    pointer-events: all;
  }
  
  .submit-row-ankieta {
    display: flex;
    gap: 10vw;
    justify-content: space-between;
    margin: 22px auto;
  }
  
  .button-questionnaire{
    display: flex;
      justify-content: center;
      align-items: center;
      width: 40vw;
      height: 7vh;
      color: #fff;
      background: #0b3389;
      background: #0b3389;
      border-radius: 50px;
      border-color: #fff;
      font-size: auto;
      font-weight: auto;
      cursor: pointer;
  }
  



}