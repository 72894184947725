
.Kontakt {
    background: url('../Components/images/welcomepage-logo.png') center/cover;
    height: 100vh;
    background-size: cover;

}

.git_kontakt {
    grid-area: 4 / 1 / 5 / 3; 
    position: absolute;
    margin-top: 2vh;
    bottom: -10px;
    left: 0;
    width: 100%;
    background-color: transparent;
    backdrop-filter: blur(7px); 
    color: rgb(255, 255, 255);
    padding: 10px;
    text-align: center;
    z-index: 2;
  }

.logo_na_dodatkowych {
    position: absolute; 
    top: 0; 
    left: 0; 
    height: 12.5vh;
    overflow: hidden;
    cursor: pointer;
    background-color: transparent;
    color: #000;
    object-fit: fill;
    width: fit-content;
    z-index: 1;
    
}
.blur_kontakt {
    top: 100vh;
}
.masz_pytania {
    height: 50px;
    margin-bottom: 35px;
}
.maile {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 3px solid white;
    display: flex;
    height: 300px;
    width: 600px;
    flex-direction: column; 
    justify-content: center;
    color: azure;
    background-color: #2d2c89;
    border-radius: 25%;
    text-align: center;  /* Dodane dla wyśrodkowania tekstu */
    padding-right: 5vh;
}
.kontakt_tekst {
    left: 10vh;
}

.maile li:nth-child(odd) {
    margin-top: 20px; /* Apply margin to 1st, 3rd, 5th, etc. li elements */
}
.maile li{

    padding-top: 7px;
}

.maile ul {
    margin-top: -40px;
}
@media (max-width: 768px) {
    .maile {
        width: 90%; /* Adjust the width for smaller screens */
        margin-top: 3vh;
        height: 22rem;
    }
    .Kontakt {
        height: 100vh;
    }
}
@media (max-width: 280px){
    .kontakt_tekst {
        font-size: 13px;
    }
}