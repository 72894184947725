/* CSS */
.boxoffert {
  display: grid;
  flex-direction: column;
  margin-top: 6%;
  align-content: space-between;
  gap: 25px;
  height: 600px;
  overflow-y: auto;
  position: absolute;
  top: 100px;
  left: 40%;
  padding-right: 10px;
}

.boxoffert::-webkit-scrollbar {
  background-color: white;  
  border-radius: 25px;
}

.boxoffert::-webkit-scrollbar-thumb {
  background-color: #393785;
  border-radius: 25px;
}

.OfferBoxWelcome_passive {
  z-index: 100;
  position: relative;
  width: 800px;
  padding: 1%;
  border: 0.3em solid #ddd;
  background-color: #393785;
  color: #ddd;
  border-radius: 70px;
  cursor: pointer;
  height: 200px; /* Initial height for collapsed state */
  overflow: hidden;
  transition: height 0.5s ease;
  display: flex;
  flex-direction: column;
}



.OfferBoxWelcome_act {
  height: min-content; /* Height for expanded state */
}




.expandButton {
  background-color: #fff;
 
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  z-index: 10;
}

.log_to_like {
  font-weight: 600;
  font-size: 12px;
  width: 200px;
  height: 30px;
}

.logoOfferta {
  max-width: 110px;
  background-color: #393785;
  height: 90px;
  width: 90px;
  object-fit: contain;
  z-index: 1;
}

.bottomButtonsROW {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.right_side_offer {
  
  
}

.log_boxa {
  display: flex;
  flex-direction: row;
  padding-left: 25px;
  padding-top: 20px;
  margin-bottom: 10px;
}

.log_boxa_info {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-grow: 1; /* Add this to take available space */
}

.buttons_offer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.aplikujbuttonOffer {
  height: 40px;
  width: 100px;
  border-radius: 5px;


}
.likeButtonWe {
    height: 40px;
    border-radius: 5px;
    align-items: flex-end; 

   
}

.scrollbarOffer {
  display: flex;
  flex-direction: row;
  width: 600px;
}

.scrollbarOffer_act {
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 500px) {
  .OfferBoxWelcome_passive {
    width: 100%;
    height: 180px;
    border-radius: 13%;
  }
  .OfferBoxWelcome_act {
    height: min-content;
    display: flex;
    flex-direction: column;
    border-radius: 6%;
    padding-bottom: 10px;
  }
  
  

  .scrollbarOffer {
    width: 100%;
    padding: 10px;
  }
  .logoOfferta {
    height: 70px;
    width: 70px;
  }
  .buttons_offer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
  }
  .aplikujbuttonOffer {
    font-size: 11px;
    width: 80px;
  }
  .likeButtonWe {
    font-size: 11px;
  }
}
@media screen and (max-width: 500px) {
  .aplikujbuttonOffer {
    font-size: 9px;
    width: 70px;
    height: 30px;
  }
  .likeButtonWe {
    font-size: 9px;
    height: 30px;
  }

  .OfferBoxWelcome_passive {
    
    padding-bottom: 20px;
  }
  
}