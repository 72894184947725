.Forgot {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  background: url('../Components/images/welcomepage-logo.png') center/cover;
  height: 100vh; /* Adjust this value as needed */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
}

.containerForgot {
  display: flex;
  flex-direction: column;
  padding: 30px; /* Adjust padding */
  width: 60vh; /* Adjust width as needed */
  background: #fff;
  border: 2px solid #767676;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  align-items: center; /* Align items in the flex container */
}

.inputForgot {
  margin: 30px 0; /* Give some space between the inputs */
  width: 80%; /* Adjust width as needed or keep it auto */
  height: 70%;
  background: #eaeaea;
  border-radius: 30px;
  padding: 25px; /* Add some padding inside the input container */
}

.inputForgot input {
  width: 100%;
  height: 60%; /* Make input take the full width of its parent */
  background: transparent;
  border: none;
  outline: none;
  color: #797979;
  font-size: 19px;
}
.ForgotPassButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 59px;
    color: #fff;
    background: #393785;
    border-radius: 50px;
    border-color: #000000;
    font-size: 19px;
    font-weight: 700;
    cursor: pointer;
}
.ForgotPassButton:hover {
  border-color: #fff;
  background: #282661;
}