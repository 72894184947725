html, body {
  margin: 0;
  padding: -10px;
  position: relative;
  height: 100%;
  font-family: 'Montserrat'
}
.welcomePage {
  background: url('../Components/images/welcomepage-logo.png') center/cover;
  min-height: 130vh;
  z-index: 10;
  overflow-y: auto;  /* Allow scrolling */

  
}

.git_footer_welcome {

  position: absolute;
  bottom: -30vh;
  left: 0;
  height: 140px;
  width: 100%;
  background-color: transparent;
  backdrop-filter: blur(7px);
  color: rgb(255, 255, 255);
  padding: 2vh;
  text-align: center;
  z-index: 2;
}

.toggle-sidepanel-button {
  position:relative;
  top: 100px;
  left: 150px;
  z-index: 999;
  margin-top: 10px;
  height: 30px;
  width: 130px;
  font-weight: bold;
  border-radius: 10px; 
  font-size: 15px;
  justify-content: center;
  align-items: center;
  display: flex;
}


.button_znajdz:hover {
  background-color: #858585;
}

.boxoffert {
  display: grid;
  flex-direction: column;
  margin-top: 6%;
  align-content: space-between;
  gap: 25px;
  height: 600px;
  overflow-y: auto;
  
  position: absolute;
  top: 100px;
  left: 40%;
  padding-right: 10px;
  
}
.boxoffert::-webkit-scrollbar{
  
  background-color: white;  
  border-radius: 25px;
}
.boxoffert::-webkit-scrollbar-thumb{
  background-color: #393785;
  border-radius: 25px;

}


.left_side_box {
  font-size: 12px;
}
.sidepanel-hidden .boxoffert {
  margin-left: 0;
}
.transition-active {
  margin-left: 0 !important;
}

.toggle-sidepanel-button {
  position: absolute;
  left: 340px;
  z-index: 950;
  height: 50px;
  width: 50px;
  border: black solid;
  background: #fff;
  cursor: pointer;
  transition: left 0.5s ease;
}

.toggle-sidepanel-button.active {
  position: absolute;
  left: 5px;
  z-index: 950;
  height: 50px;
  width: 50px;
  border: black solid;
  background: #fff;
  cursor: pointer;
  transition: left 0.5s ease;
}
.hamburger-icon {
  position: absolute;
  z-index: 950;
  content: "";
  height: 25px;
  width: 25px;
  border-top: 2px solid #000;
  border-left: 2px solid #000;
  transform: rotate(-45deg);
  top: 10px;
  left: 15px;
  
}

.hamburger-icon.active {
  position: absolute;
  z-index: 950;
  content: "";
  height: 25px;
  width: 25px;
  border-top: 2px solid #000;
  border-left: 2px solid #000;
  transform: rotate(135deg);
  top: 10px;
  left: 5px;
}

.regulamin-section {
  background-color: #000;
  color: #fff;
  padding: 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  opacity: 0; /* Ukryj okno regulaminu na początku */
  transition: opacity 0.3s ease;
}

.regulamin-section.visible {
  opacity: 1; /* Pokaż okno regulaminu, gdy zostanie dodana klasa "visible" */
}

.regulamin-box {
  max-width: 800px;
  margin: 0 auto;
}


.regulamin_naglowek {
  color: #fff;
  border-bottom: 1px solid #fff;
  padding-bottom: 10px;
}
.no_ofert_msg {
    margin-top: 39vh;
    margin-left: 62vw;
    margin-right: 20%;
    transform: translate(-50%, -50%);
    z-index: 3;
    display: flex;
    height: 25vh;
    width: 55vw;
    flex-direction: column; 
    justify-content: center;
    color: azure;
    background-color: #2d2c89;
    align-items: center;
    border-radius: 25%;
    text-align: center;
    padding-bottom: 1%;
    border: 3px solid white;

}


@media screen and (max-width: 500px) {
  .boxoffert {
    display: grid;
    width: 95%;
    overflow-y: auto;
    position: absolute;
    left: 5px;

  }
  .boxoffert {
    height: 800px;
    font-size: 11px;
    width: 95%;
    padding-left: 10px;
  }
}

@media  screen and (max-width: 400px) and (max-height: 740px){
  .boxoffert {
    height: 600px
  }
  
}