html, body {
    margin: 0;
    padding: 0;
   
}


@media (max-width: 768px) {
    
    .side_panel-grid {
        padding: 0vh 1.7vh;
        z-index: 899;
        font-family:  'Montserrat';
        font-size: 15px;
        background-color: #2d2c89;
        display: flex;
        grid-template-columns: repeat(1, (auto, 1fr));
        grid-template-rows: repeat(7,minmax( auto, 1fr));
        position:absolute;
        flex-direction: column;
        height: 100px;
        width: 100vw;
        top: 75px;
        border-radius: 10px;
    }
}

.side_panel-grid {
    margin-top: 3.5vh;
    padding: 0vh 1.7vh;
    z-index: 899;
    font-family:  'Montserrat';
    font-size: 15px;
    background-color: #2d2c89;
    display: flex;
    grid-template-columns: repeat(1, (auto, 1fr));
    grid-template-rows: repeat(7,minmax( auto, 1fr));
    position:absolute;
    flex-direction: column;
    height: 650px;
    width: 410px;
    top: 55px;
    border-radius: 10px;
}

.text {
    font-family: 'Montserrat';
    
    opacity: 0;
    color: #000000; 
    position: relative;
    background-color: #393785;
    margin: 0;
    right: 0;
    top: 100%;
    transition: opacity 0.02s;
    width: max-content; 
  }
.grid-row {
    color: white;
    display:flex;
    justify-content: space-between;
    margin-top: 70px;
    
    
}

.opis_filtry {
    width: 80%; 
    height: 1fr; 
    
    display: flex;
    align-items: center;
    
    
}
.search_container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.search_button {
    margin-top: 15px;
    height: 30px;
    width: 100px;
    font-weight: bold;
    border-radius: 10px; 
    font-size: 15px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.miejsce-lists,
.umowa-lists,
.poziom-lists {
    display: flex;
    margin-left: 10px;
    height: 3vh;
    width: 160px; 
    
    margin-right: 5px;
}
.tik {
    width: 90%;
    margin-left: 40%;
    
}

.tik_css_tak {
    font-size: 100%;
    
    /* width: 60%; */ /* Remove this line */
    /* Adjust margin as needed */
    margin-left: 5px;
    margin-right: 5px;
}
.tik_css {
    width: 10%;
    height: 10%;
}
