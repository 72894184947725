.footer {
    position: absolute;
   
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: transparent;
    backdrop-filter: blur(7px); 
    color: rgb(255, 255, 255);
    padding: 10px;
    text-align: center;
    transform: translateY(100%);
    transition: transform 0.3s ease-in-out;
  }
  
  .footer.visible {
    
    transform: translateY(0);
  }