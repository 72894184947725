
:root{
  --text-color: #ececec;
  --custom-font:  'Montserrat';
}
.Navbar {
  
  font-family:  'Montserrat';
  font-size: 20px;
  background-color: #2d2c89;
  display: flex;
  grid-template-columns: repeat(7, minmax(auto, 1fr));
  grid-template-rows: repeat(1, 1fr);
  position: fixed; 
  top: 0; 
  left: 0;
  right: 0;
  z-index: 1000; 
  height: 95px;
  flex-direction: row;

}

.user_photo {
  width: 60px;
  height: 60px;
}

.email_linijka{

  max-width: 170px;
  font-size: 15px;
  position: relative;
  color: #000000;
}
.logo {
  overflow: hidden;
  cursor: pointer;
  background-color: transparent;
  color: #000000;
  width: 100px; /* Adjust the width as needed */
  height: 80px; /* Adjust the height as needed */
  object-fit:fill;
  
  height: fit-content;
  margin-top: 15px;
  left: 50%;
}

.text {
  font-family: 'Montserrat';
  opacity: 0;
  color: #000000; 
  position: absolute;
  background-color: #2d2c89;

  padding: 10px 20px;
  margin: 0;
  left: 0;
  top: 100%;
  transition: opacity 0.02s;
  width: max-content; 
}



.text.visible {
  opacity: 1;
}

.div5_hidden {
  display: none;
}

.div6_hidden {
  display: none;
}
.div1 { 

  text-align: center; 
  padding: 1%;   
  border: 0.01px solid black; 
  transition: border-color 0.3s;
  background-color: white;
  width: 140px;
  height: 115px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 95px;
  
}
.div2 { 

  background-color: #2d2c89;
  flex-grow: 1; 
  text-align: center; 
  padding: 1%;  
  border: 0.01px solid black; 
  transition: border-color 0.3s;
  color:white;
  text-align: center;
  display: flex; 
  align-items: center;
  justify-content: center;
  height: 95px;
}
.div3 { grid-area: 1 / 3 / 2 / 4; 
  background-color: #2d2c89;
  flex-grow: 1; 
  text-align: center; 
  padding: 1%;  
  border: 0.01px solid black; 
  transition: border-color 0.3s;
  color:white;
  text-align: center;
  display: flex; 
  align-items: center;
  justify-content: center;
  height: 95px;
}
.div4 { grid-area: 1 / 4 / 2 / 5; 
  background-color: #2d2c89;
  flex-grow: 1;
  text-align: center; 
  padding: 1%;  
  border: 0.01px solid black;  
  transition: border-color 0.3s;
  color:white;
  text-align: center;
  display: flex; 
  align-items: center;
  justify-content: center;
  height: 95px;
}
  
.div5 { grid-area: 1 / 5 / 2 / 6; 
  background-color: #2d2c89;
  flex-grow: 1;
  text-align: center; 
  padding: 1%;  
  border: 0.01px solid black; 
  transition: border-color 0.3s;
  color:white;
  text-align: center;
  display: flex; 
  align-items: center;
  justify-content: center;
  height: 95px;
}
.div6 { grid-area: 1 / 6 / 2 / 7; 
  background-color: #2d2c89;
  flex-grow: 1%; 
  text-align: center; 
  padding: 1%;  
  border: 0.01px solid black; 
  transition: border-color 0.3s;
  color:white;
  text-align: center;
  display: flex; 
  align-items: center;
  justify-content: center;
  
}



.div7 { grid-area: 1 / 7 / 2 / 8; 
  background-color: #2d2c89;
  flex-grow: 0.3;
  text-align: center; 
  padding: 1%; 
  border: 0.01px solid black;  
  transition: border-color 0.3s;
  color:white;
  text-align: center;
  display: flex; 
  align-items: center;
  justify-content: center;
  
}

.div1:hover{
  border-color: white;
  }
.div2:hover{
    border-color: white;
  }
.div3:hover{
  border-color: white;
  }
.div4:hover{
  border-color: white;
  }
.div5:hover{
  border-color: white;
  }
.div6:hover{
  border-color: white;
  }
  .div7:hover{
    border-color: white;
    }
    
html, body {
  margin: 0;
  padding: 0;
}


ul{
  list-style: none;
}

a {
  text-decoration: none;
}

.menu-trigger img{
  position: absolute;
  top: 15%;
  right: 1.2%;
  min-height: 20%;
  max-height: 60%;
  border-radius: 25%;
  overflow: hidden;
  cursor: pointer;
  background-color: #fff;
}


.homelogin {
  
  margin-top: 0.01px ; 
  margin-right: 0.01px ;
  
  height: 50px;
  width: 210px;
  color: white;
  background: #2d2c89;
  font-weight: 550;
  cursor: pointer;
  padding: 14px 2.5px; 
  font-size: 20px;
}
.homelogin:hover {
  border-color: rgb(0, 0, 0);
}

.dropdown-menu{
  z-index: 2;
  position: absolute;
  top: 100px;
  right: 10px;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 30px;
  
  
}



.dropdown-menu.active{
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: 500ms ease;
  width: 270px;
  box-shadow: 0 0 0.5px 0.5px #000000;
}

.dropdown-menu.inactive{
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: 500ms ease;
  width: 270px;
}




h3 span{
  color: #000000;
  font-weight: 400;
  
}

.dropdown-menu ul li{
  width: auto;
  padding: 10%;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.dropdown-menu ul li:hover a{
  color: rgb(0, 0, 0);
  cursor: pointer;
}

.dropdown-menu ul li:hover img{
  opacity: 1;
  cursor: pointer;
}

.dropdownItem{
 display:flex;
 
}

.dropdownItem img{
  
  margin-left: -60px;
  width: 50px;
  opacity: 0.5;
  transition: 500ms;
}

.dropdownItem a{
  
  transition: 500ms;
}



.toggle-navbar-button {
  position: absolute;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border-radius: 10px; 
  font-size: 15px;
  display: flex;
  top: 21px;
  left: 5px;
  z-index: 999;
  height: 50px;
  width: 50px;
  border: black solid;
  background: #fff;
  cursor: pointer;
}

.navburger-icon {
  width: 30px;
  height: 3px;
  background-color: black;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.3s, top 0.3s, transform 0.3s;
}

.navburger-icon::before,
.navburger-icon::after {
  content: '';
  width: 30px;
  height: 3px;
  background-color: black;
  position: absolute;
  left: 0;
  transition: width 0.3s, top 0.3s, transform 0.3s;
}
.navburger-icon::before {
  top: -10px;
}

.navburger-icon::after {
  top: 10px;
}

.navburger-icon.active {
  width: 40px;
  height: 3px;
  background-color: black;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}

.navburger-icon.active::before {
  content: '';
  width: 40px;
  height: 3px;
  background-color: black;
  position: absolute;
  top: 0px;
  left: 0px;
  transform: rotate(90deg);
}

.navburger-icon.active::after {
  content: '';
  width: 40px;
  height: 3px;
  background-color: black;
  position: absolute;
  top: 0px;
  left: 0px;
  transform: rotate(-90deg);
}

@media screen and (max-width: 768px) {

  .Navbar {
    height: 10vh;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 500;
  }
  .div1 {
    width: 115px; /* Adjust the width as needed */
    height: 100px;
  }
  .Navbar > div {
    width: 100%;
    text-align: center;
    border-color: #2d2c89;
    padding: -2px;
  }
  .logo {
    overflow: hidden;
    cursor: pointer;
    background-color: transparent;
    color: #000000;
    margin-top: 15px;
    left: 50%;
  }
  .user_photo {
    width: 50px;
    height: 60px;
    
  }
  .email_linijka{
    
    max-width: 130vw;
    font-size: 4vw;
    position: relative;
    color: #000000;
  }

  
  


  
  .div1:hover{
    border-color: white;
    }
  .div2:hover{
      border-color: white;
    }
  .div3:hover{
    border-color: white;
    }
  .div4:hover{
    border-color: white;
    }
  .div5:hover{
    border-color: white;
    }
  .div6:hover{
    border-color: white;
    }

      
  .toggle-sidepanel-button{
    display: block;
    z-index: 999;
    position: absolute;
  }
}
