.ToFind {
  
  background: url('../Components/images/welcomepage-logo.png') center/cover;
 

}
.wszystko {
  margin: 50px;
}

form {
font-weight: 400;
display: flex;
align-items: center;
flex-direction: column;
margin-top: 1%;
width: 650px;
height: 550px;
background: #2d2c89;

padding-bottom: 30px;
border: 3px solid #ffffff;
border-radius: 35px;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
margin-bottom: 1%;
}
.input_tofind {
  display: flex;
  align-items: center;
  color: black;
  margin: 10px;
  width: 600px;
  height: 100px;
  background: white;
  border-radius: 30px;
}
.input_tofind input {
  height: 100px;
  width: 600px;
  background: transparent;
  border: none;
  outline: none;
  color: black;
  font-size: 19px;

}

.logo_na_dodatkowych {
  position: absolute; 
  top: 0; 
  left: 0; 
  height: 125px;
  overflow: hidden;
  cursor: pointer;
  background-color: transparent;
  color: #000;
  object-fit: fill;
  width: fit-content;
  z-index: 1;
}

.firm-contact-window {
  display: flex;
  border-radius: 3px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: white;
  align-items: center;
  justify-content: center;
  z-index: 0;
  
}


.button3637 {
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.7rem 2rem;
  
  font-size: 18px;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  width: 200px;

  border: 0.3rem ;
  border-radius: 30px;
  color: white;
  background-color: #ffffff;
  color: #000;
  font-weight: 500;
  position: relative;
}




.button3637:active {
  background-color: #ffffff;
  color: black;
}
.firm-contact-window h2 {
  text-align: center;
  height: 45px;
  width: calc(100% + 50px);
  padding-top: 6px;
  background-color: #2d2c89;
  border-radius: 30px;
  border: 2px solid #ffffff;
}
.textholderko {
  display: flex;
  justify-content: center;
  margin-top: 146px;
  font-weight:500;
  height: 50px;
  width: 1250px;
  margin-bottom: 10px;
  
  
}
.h2tofind {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2d2c89;
  height: 60px;
  width: 1100px;
  border-radius: 30px;
  border: 3px solid white;
  font-size: 21px;
}

@media (max-width: 767px) { 

  .ToFind {
    width: fit-content;
    height: fit-content;
  }

  .textholderko {
    width: 85vw;
    margin-bottom: 10vh;
  }
  .h2tofind {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 85vw;
    height: 18vh;
    font-size: 5vw;
  }

  .form_tofind_container {
    width: 90vw;
    height: 70vh;
    margin-top: 10vh;

  }

  .input_tofind {
    width: 75vw;
    height: 10vh;
  }
  
}