.login_page {
  margin-top: 0;
  
  width: 100vw;
  min-height: 1000px;
  background: url('../Components/images/welcomepage-logo.png') center/cover;
  padding: 20px;
}


.git {
grid-area: 4 / 1 / 5 / 3; /* Umieść stopkę na samym dole strony */
position: relative;
margin-top: 20px;
bottom: 0;
left: 0;
width: 100%;
background-color: transparent;
backdrop-filter: blur(7px); 
color: rgb(255, 255, 255);
padding: 10px;
text-align: center;
z-index: 0;
}
.polityka-link span {
color: white;/* Twój wybrany kolor dla Polityki prywatności */;
}

.regulamin-link span {
color:white;/* Twój wybrany kolor dla Regulaminu strony */;
}

.container {
flex: 1;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 90px;
  width: 700px;
  background: #2d2c89;
  padding-bottom: 30px;
  border: 4px solid white;
  border-radius: 35px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.SignOrLogin {
  color: white;
  font-size: 48px;
  font-weight: 700;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;
  width: 100%;
  margin-top: 30px;
}

.TextWindow {
  color: #000000;
  font-size: 48px;
  font-weight: 700;
}

.underline {
  width: 61px;
  height: 6px;
  background: white;
  border-radius: 9px;
}

.inputs {
  margin-top: 55px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:20px;
 
}

.input_login {
  display: flex;
  align-items: center;
  margin: auto;
  width: 600px;
  height: 100px;
  background: white;
  border-radius: 40px;
  border-color: white;
  font-size: 19px;
}

.input_login input {
  height: 100px;
  width: 600px;
  background: transparent;
  border: none;
  outline: none;
  color: black;
  font-size: 19px;

}

.forgot-password {
  padding-left: 10px;
  margin-top: 0px;
  color: white;
  font-size: 18px;
}

.forgot-password span {
  color: white;
  gap: 30px;
  margin: 60px auto;
}

.submit-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 4px auto;
}
.submit_row {
display: flex;
flex-direction: column; 
gap: 0px; 
justify-content: center;
align-items: center; 
margin: 0px 0;
}
.submit_file {
  position: relative;
  background-color: transparent;
  color: #e8e8e8;
  font-size: 17px;
  font-weight: 600;
  border-radius: 10px;
  width: 150px;
  height: 60px;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
  overflow: hidden;
  box-shadow: 0 10px 15px #0b3389;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.320, 1);
}

.submit_file::before {
  content: "Kliknij";
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: #2d2c89;
  border: 3px solid white; 
  border-radius: 10px;
  transform: translate(0%,90%);
  z-index: 99;
  position: relative;
  transform-origin: bottom;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.submit_file::after {
  content: "Załaduj CV";
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border: 3px solid white; 
  width: 100%;
  height: 100%;
  pointer-events: none;
  transform-origin: top;
  transform: translate(0%,-100%);
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.submit_file:hover::before {
  transform: translate(0%,0%);
}

.submit_file:hover::after {
  transform: translate(0%,-200%);
}

.submit_file:focus {
  outline: none;
}

.submit_file:active {
  scale: 0.95;
}


.selected-filename {
  font-size: 14px;
  color: #777;
  margin-top: 15px;
}
.submit_file_cv {
  display: none;
}


.submit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 190px;
  height: 59px;
  color: black;
  background: white;
  border-radius: 50px;
  border-color: #fff;
  font-size: 19px;
  font-weight: 700;
  cursor: pointer;
}



html, body {
  margin: 0;
  padding: 0;
}
.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
 
}
.submit-gray {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 75px;
  color: #fff;
  background: #0b3389;
  border-radius: 50px;
  border-color: #fff;
  font-size: 19px;
  font-weight: 700;
  cursor: pointer;
}

.submit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 59px;
  background: white;

  border-radius: 50px;
  border-color: #ffffff;
  font-size: 19px;
  font-weight: 600;
  cursor: pointer;
}

.gray {
  background: #eaeaea;
  color: #676767;
}


@media (max-width: 767px) {
  .login_page {
    width: fit-content;
    height: fit-content;
  }
  .container {
    width: 90vw;
  }
.inputs {
  width:33vw;

}
.input_login {
  transform: translateX(5%);
  height: 60px;
  width: 80vw;
}

.submit_file {
  transform: translateX(75%);
}
.forgot-password {
  transform: translateX(30%);
  width: 80vw;
}
.submit {
  transform: translateX(57%);
  width: 50vw;
}
.submit-gray {
  transform: translateX(25%);
  width: 60vw;
}
.SignOrLogin {
  text-align: center;
  font-size: 9vw;
}

}