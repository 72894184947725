html, body {
    margin: 0;
    padding: 0;
}

.button_znajdz_div {
    position: absolute;
    top: 500px;
    left: 800px;
    background-color: azure;
    align-items: center;

}
.button_znajdz {
    height: 40px;
    width: 300px;
    border-radius: 10px;
    border: #fff solid;
    background-color: #b2b2b2;
    transition-duration: 0.4s;

}

.button_znajdz:hover {
    background-color: #858585;
}

.oferta_box {
    display: flex;
    flex-direction: column;
    margin-left: 10%;
    border-radius: 10px;
    border: #858585 solid;
    background-color: #b2b2b2;
    overflow-y: auto;
}
.OfferBoxWelcome {
    position: relative;
    margin: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    background-color: #cfcfcf;
    border-radius: 10px;
    cursor: pointer;
    overflow: auto;
    height: 300px;
    transition: 1s ease;
}
.OfferBoxWelcome.tak {
    position: relative;
    margin: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    background-color: #cfcfcf;
    border-radius: 10px;
    cursor: pointer;
    overflow: auto;
    height: 300px;
    transition: 1s ease;
}
.OfferBoxWelcome.nie {
    position: relative;
    margin: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    background-color: #cfcfcf;
    border-radius: 10px;
    cursor: pointer;
    overflow: auto;
    height: 300px;
    transition: 1s ease;
}
.OfferBoxWelcome.active {
    margin: 20px;
    height: 800px;
    padding: 10px;
    border: 1px solid #ddd;
    background-color: #cfcfcf;
    border-radius: 10px;
    cursor: pointer;
    transition: 1s ease;
}
.ofertaFlyer {
    margin: 20px;
    overflow: hidden;
    transition: 0.4 ease;
}
.ofertaFlyer.active {
    max-height: 200px;
    background: #fff;
}
.log_box {
    height: 50px;
    width: 50px;
    background: #a9a9a9;

}
.bottomButtons {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 40px;
    width: 700px;
    padding: 10px;
    background-color: #cfcfcf;
    border-top: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
}

.aplikujbutton,
.AddToFavorites {
    height: 30px;
    
    width: 100px;
    border-radius: 5px;
    margin: 0 5px;
}