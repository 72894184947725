.AnkietaPage {


  background: url('../Components/images/homepage-logo.png') center/cover;
  width: fit-content;

position: absolute;
}
.questions-container {
  display: flex;
  flex-wrap: wrap;
}

.div3 { grid-area: 1 / 2 / 2 / 3; }



.poziom_gwiazdki {
  margin-top: 100px;
  
    width: 900px;
    padding: 30px;
    border: 3px solid #ddd;
    background-color: #393785;
    color: #ddd;
    border-radius: 70px;
    cursor: pointer;
    overflow: hidden;
    height: 400px;
    transition: 1s ease;
  
}
.div2_ankieta {
  width: 600px;
  padding: 1%;
    border: 3px solid #ddd;
    background-color: #393785;
    color: #ddd;
    border-radius: 70px;
    cursor: pointer;
    overflow: hidden;
    height: 250px;
    display: flex;
   
}
.div2_ankieta.expanded {
  height: auto;
}
.wpisane_ankieta {
  height: 50px;
  
}

.srodek_div2 {
  margin-left: 3%;
}
.question-item {
  width: 295px;
  height: 200px;
  margin-left: 10px; 
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-sizing: border-box;

}





input[type="radio"] {
  display: none;
}

.rating label {
  cursor: pointer;
  font-size: 2rem;
}

.star {
  color: grey;
}

.star.yellow {
  color: yellow; 
}

.star-yellow {
  color: yellow; 
}

.TekstAnkiety {
  display: flex;
  height: 50px;
  width: 300px;
  border: 1px #665e5e;
  margin: 5px;
  align-items: center;
  border-radius: 14px;
  background: #dbdbdb;
}

.przyciski_dodaj {
  
  color: black;
  padding: 10px 10px;
  border-radius: 5px;
  cursor: pointer;
}
.przyciski_przeslij {
  background-color: #393785;
  color: white;
  padding: 1vh 1vh;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 1vh;
  margin-top: 1vh;
}

.poziom_gwiazdki p{
  display: flex;
  align-items: center;
  font-size: 25px;
  margin-left: 3vh;
}

.przyciski_usun {
  background-color: #ffffff; /* Kolor czerwony */
  color: rgb(0, 0, 0);
  padding: 0.5vh 1vh;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
  margin-top: 10px;
}


@media screen and (max-width: 768px) {

  
.AnkietaPage {
  width: fit-content;
}
 .poziom_gwiazdki {
  overflow-y: auto;
  width:135vw;
  height: 450px;
  font-size: 1.7vh;
}

.poziom_tekst {
  font-size: 1.5vh;
}
.question-item {
  height: 20vh;
  width: 50vw;
  font-size: 2.5vh;
}
.div2_ankieta {
  height: 33vh;
}
.srodek_div2 {
  font-size: 3vh;
}
.przyciski_dodaj {
  height: 5vh;
  width: 30vw;
  font-size: 2vh;
}
.przyciski_przeslij {
  height: 5vh;
  width: 50vw;
  font-size: 2vh;
}
.TekstAnkiety {
  font-size: 10px;
  
}
}
