
:root{
    --text-color: #232D3F;
    --circle_font-size: 24px;
}
html, body {
    margin: 0;
    padding: 0;
    
}

.grid {
    display: grid;
    grid-template-columns: 1fr 600px 1fr;
    grid-template-rows: 40px 600px 1fr;

}
.HOME {
    display: flex;
    align-items: center;
    justify-content: center;
    background: url('../Components/images/homepage-logo.png') center/cover;
    height: 1000px;
    z-index: 0;
}



.git_home {
    position: absolute;
    margin-top: 20px;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: transparent;
    backdrop-filter: blur(7px); 
    color: rgb(255, 255, 255);
    padding: 10px;
    text-align: center;
    z-index: 0;
  }




.center_circle {
    height: 30%;
    margin-bottom: 20%;
}
.centered-image {
    
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 290px;
    background: url('../Components/images/pq-logo.webp') center/cover;
    z-index: 1;
    width: 202px;
    height: 175px;
}
.centered-image img {
    
    width: 180.2px;
    height: 180.2px;
    width: fit-content;
}
.lol {
    margin-top: 20%;
    position: absolute;
    top: 45%; 
    left: 50%;
    width: 1000px;
    transform: translate(-50%, 0);
    color: azure;
    text-align: center;
    font-size: 40px;
    font-family:  'Montserrat';
}

@media (max-width: 767px) {
    .HOME {
      width: 100%;
      
      
    }
    .lol {
        width: auto;
        top: 40vh;
        left: 50%;
        font-size: 35px;
        text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black;
    }

   
    .center_circle {
        margin-bottom: 40vh;
    }

    .centered-image img {
    
        
        height: 180.2px;
        width: 215.3px;
    }

}
@media (max-width: 360px) {
    .lol {
        top: 50vh;
    }

 }

 @media (max-height: 735px) and (max-width: 488px){
    .lol {
        margin-top: 25%;
    }
 }