html, body {
  margin: 0;
  padding: 0;
}


  .UserProfile {
    display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  grid-template-areas:
    "Navbar OfferContainer"
    "wizytowka_userprofile OfferContainer"
    "Cv_ankieta-userprofile OfferContainer";
    min-height: 190vh;
    width: max-content +5vh;
  overflow-x:hidden;
  overflow-y: hidden;
background: url('../Components/images/welcomepage-logo.png') center/cover;
z-index: 10;
  }

.input_div {
color: black;
}
.wizytowka_userprofile {
display: grid;
  grid-template-columns: auto;
  margin-top: 90px;
  margin-left: 30px;
  width: 500px;
  font-size: large;
border: 4px solid #ddd;
background-color: #393785;
color: #ffffff;
border-radius: 70px;
cursor: pointer;

height: 400px;

}
.git_footer_user {
grid-area: 4 / 1 / 5 / 3; /* Umieść stopkę na samym dole strony */
position: relative;
bottom: 0;
left: 0;
width: 100%;
background-color: transparent;
backdrop-filter: blur(7px); 
color: rgb(255, 255, 255);
padding: 10px;
text-align: center;
z-index: 1;
}

.git_footer.visible {
transform: translateY(0);
}

.git_footer p {
margin: 0;
}

.Cv_ankieta-userprofile {
  position: relative;
  width: 600px;
  padding: 1%;
  border: 4px solid #ddd;
  background-color: #393785;
  color: #ddd;
  border-radius: 70px;
  cursor: pointer;
  overflow: hidden;
  height: min-content;
  margin-left: -25px;
  transition: 1s ease;
  left:7vh;
  z-index: 998;
margin-top: 70px;
}



.Dane_userprofile {
  grid-area: 3 / 2 / 1 / 2;
  display: flex; 
  align-items: flex-start; 
  margin-left: 10px;
  min-width: 300px;
 
}

.Dane_userprofile ul {
  list-style: none;
  padding: 0;
  
}

.Dane_userprofile li {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  
}


.field_box {
  display: flex;
  height: 40px;
  min-width: 70%;
  max-width: 80%;
  border: 1px #665e5e;
  margin: 5px;
  align-items: center;
  border-radius: 14px;
  background:transparent;

}
.aplikacje_user {
  position: absolute;
  margin-left: 30px;
  width: 650px;
  height: 1000px;
  vertical-align: top;
  top: 160px;
  right: 80px;
  border: 1px #665e5e;
  border-radius: 14px;
  background: #f4f6f4;

}
.aplikacje_user h1 {
  display: flex;
  align-items: center;
  align-content: center;
  margin-left: 100px;
}
.oferta {
  display: flex;
  width: 500px;
  height: 200px;
  align-items: center;
  align-content: center;
  margin-left: 50px;
  margin-top: 20px;
  margin-right: 60px;
  margin-bottom: 20px;
  border: 1px #b2aaaa;
  border-radius: 14px;
  background: #789e78;
}
.img_aplikacje {
  position: relative;
  top: -50px;
  left: 10px;
  width: 60px;
  height: 60px;
  background-color: #c7c7c7;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Console
  monospace;
}
.star {
  color: grey;
}

.star.yellow {
  color: yellow; 
}

.star-yellow {
  color: yellow; 
}
.left_userprofile {
  display: grid;
  grid-area: 1 / 1 / 3 / 2;
  
}
.right_userprofile {

grid-area: 1 / 2 / 3 / 3;
margin-left: 60px;


}
.OfferContainer {
  
  display: grid;
  width: 1100px;
  height: 600px; 
  overflow-y: auto;
  overflow-x:hidden ;
  padding: -px;
  margin-top: 13.5%; 
  margin-right: 10px;
  margin-bottom: 100px;
  margin-left: 20px;
  justify-self:flex-end;
  min-width: 400px;
  
  border-radius: 14px;
  background-color: transparent;
  
}

.naglowek_user {
background-color: #393785;
width: 460px;
height:40px;
text-align: center;

margin-left: 40px;
border-radius: 40px;
color:white;
font-weight: 400;
border: 3px solid rgb(255, 255, 255); 
margin-top: 0px;
margin-bottom: 0;


}
.naglowek_user_liked {

background-color: #393785;
width: 300px;
height:40px;
text-align: center;
margin-left: 40px;
border-radius: 40px;
color:white;
font-weight: 400;
border: 3px solid rgb(255, 255, 255); 

margin-bottom: 0;


}
.left_userprofile_wizytowka { 
  margin-top: 10%;

}


.OfferContainer_liked {

display: grid;
width: 1100px;
height: 650px; 
overflow-y: auto;
overflow-x:hidden ;
padding: 20px;
margin-top: -100px; 
margin-right: 30px;
justify-self:flex-end;
min-width: 400px;

border-radius: 14px;
background-color: transparent;
}
.OfferBox {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
  border-radius: 14px;
  cursor: pointer;
}
.przyciski {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  max-width: 150px; 
  max-height: 150px;
  font-weight: 600;
}
.przycisk_edycji_user {
height: 30px;
width: 30px;
background-color: #393785;

}
.przycisk_ankieta {
      margin-bottom: 10px;
      margin-left: 30px;
      background-color: #ffffff;
      color: rgb(0, 0, 0);
      padding: 10px 15px;
      border-radius: 5px;
      cursor: pointer;
      max-width: 220px;
     
      font-weight: 600;
    }

.napis-ankieta {
  margin-top: 15px;
  color: white; 
  text-decoration: none;
}



@media (max-width: 768px) { 


  


  .left_userprofile_wizytowka {
    position: absolute;
    top: 7%;
    left: -2%;
    width: 90%;
    height: 50%;
    
  }
  .wizytowka_userprofile {
    

    height: 400px;
    width: 90vw;

  }
  .OfferContainer{
   
    width: 30vw;
    position: absolute;
    margin-top: 700px;
    left: -6vw;

  }
  .naglowek_user {
    display: flex;

    width: 75vw;
    height: 5vh;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.9vh;
    font-weight: 400;
    
  }

  .naglowek_user_liked {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 60vw;
    height: 5vh;
    
    font-size: 1.9vh;
    font-weight: 400;
  }

  .OfferContainer_liked {
    position: absolute;
    height: 70vh;
    width: 460px;
    top: 153vh;
    left: -6vw;
    font-size: 1.9vh;
      }
  .Cv_ankieta-userprofile {
    position: absolute;
   
    width: 95vw;
    top: 1900px;
    margin-left: -40px;
    
   }
   .git_footer_user {
    grid-area: 4 / 1 / 5 / 3; /* Umieść stopkę na samym dole strony */
    position: absolute;
    top: 352vh;
    left: 0;
    height: 20vh;
    width: 100%;
    background-color: transparent;
    backdrop-filter: blur(7px); 
    color: rgb(255, 255, 255);
    padding: 10px;
    text-align: center;
    z-index: 999;
    }
    .naglowek_user_liked {
      font-size: 1.9vh;
      font-weight: 400;
      
    }
    .UserProfile {
      height: 380vh;
    }
    .margin_offer {
    margin-top: -250px;
    margin-left: 15px;
    
    }
    
}

@media (max-width: 360px) {
  .wizytowka_userprofile {
    position: absolute;
    left: -15px;
    margin-right: 10vw;
    height: 100%;
    width: 90vw;
  }
  .git_footer_user {
    top: 348vh;
  }
  .Dane_userprofile {
    margin-left: 20px;
  }

  .UserProfile {
    height: 370vh;
  }
  .Cv_ankieta-userprofile {
    top: 134vh;
    height: fit-content;
  
  }
  .naglowek_user {
    padding-top: 12px;

  }
  .naglowek_user_liked {
    padding-top: 12px;
  }
  

 }