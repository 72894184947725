.parent {
    display: grid;
    grid-template-columns: 1fr repeat(3, 200px) 1fr;
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    }
    
    .OfferBoxDodaj { 
        grid-area: 2 / 2 / 5 / 5; 
        margin: 20px;
        height: 500px;
        width: 900px;
        padding: 10px;
        border: 1px solid #ddd;
        background-color: #cfcfcf;
        border-radius: 10px;
        cursor: pointer;
        overflow: hidden;
        overflow-y: auto;
        transition: 1s ease;
    }
    .asdasd { 
        grid-area: 1 / 1 / 6 / 2; 
    }
    .ddddd { 
        grid-area: 1 / 2 / 2 / 6; 
    }
    .eeeee { 
        grid-area: 5 / 2 / 6 / 6; 
    }
    .ffffff { 
        grid-area: 2 / 5 / 5 / 6; 
    }
    .LogoDodaj { 
        grid-area: 2 / 2 / 3 / 3;
        height: 120px;
        width: 120px;
        background: #a9a9a9; 
    
    }
    .InputsArea { 
        grid-area: 3 / 2 / 5 / 5;
        margin: 25px;
    }
    .InputsArea li {
        margin: 50px;
    }

    .INPUT {
        top: 0;
        left: 0;
        transform: translate(10px, 10px);
        font-size: 14px; 
        width: 600px;
        height: 100px;
        overflow-y: auto; 
    }
    .div2_ankieta {
        overflow-y: auto;
    }
    .srodek_div2 {
        overflow-y: auto;
        width: 100%;
    }